import { Customer } from "@contextualio/contextual-silo-auth"
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useState } from "react"

import { useCustomersService } from "../providers"
import { DeepPartial } from "../types/pages"
import { isValidUser } from "../utils/users"
import { useLoading } from "./loading"
import { useUser } from "./user"

type CustomerContext = {
  customer?: DeepPartial<Customer>
  setCustomer: Dispatch<SetStateAction<DeepPartial<Customer> | undefined>>
  fetchCustomer: () => Promise<void>
}

export const CustomerContext = React.createContext<CustomerContext | null>(null)
export const useCustomer = () => useContext(CustomerContext)!

type Props = PropsWithChildren & {}

export const CustomerProvider = ({ children }: Props) => {
  const { user } = useUser()
  const { getCustomer } = useCustomersService()
  const { loadingStates, setLoadingStatus } = useLoading()

  const [customer, setCustomer] = useState<DeepPartial<Customer>>()

  const fetchCustomer = useCallback(async () => {
    try {
      const rsp = await getCustomer()
      setCustomer(rsp)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingStatus("CustomerProvider")
    }
  }, [getCustomer, setLoadingStatus])

  useEffect(() => {
    if (!customer && !!user && isValidUser(user)) {
      fetchCustomer()
    } else {
      setLoadingStatus("CustomerProvider")
    }
  }, [customer, fetchCustomer, setLoadingStatus, user])

  if (loadingStates.CustomerProvider) {
    return null
  }

  return (
    <CustomerContext.Provider value={{ customer, setCustomer, fetchCustomer }}>
      {children}
    </CustomerContext.Provider>
  )
}
