import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react"

import { Fallback } from "../components/Fallback"

export const ProvidersKeys = {
  AxiosProvider: "AxiosProvider",
  UserProvider: "UserProvider",
  TenantsProvider: "TenantsProvider",
  CustomerProvider: "CustomerProvider",
} as const
export type ProvidersKeys = keyof typeof ProvidersKeys

type LoadingProviderKeys = {
  [key in ProvidersKeys]: boolean
}

type LoadingContext = {
  loadingStates: LoadingProviderKeys
  setLoadingStatus: (provider: ProvidersKeys, status?: boolean) => void
}

export const LoadingContext = createContext<LoadingContext | null>(null)
export const useLoading = () => useContext(LoadingContext)!

type Props = PropsWithChildren & {
}

export const LoadingProvider = ({ children }: Props) => {
  const [loadingStates, setLoadingStates] = useState<LoadingProviderKeys>({
    AxiosProvider: true,
    UserProvider: true,
    TenantsProvider: true,
    CustomerProvider: true,
  })

  const setLoadingStatus = useCallback((provider: ProvidersKeys, status = false) => {
    setLoadingStates(prevStates => ({ ...prevStates, [provider]: status }))
  }, [])

  const loading = useMemo(() => Object.values(loadingStates).some(status => status), [loadingStates])

  const LoadingSpinner = useCallback(() => {
    if (!loading) return null

    return (
      <>
        <Fallback />
      </>
    )
  }, [loading])

  return (
    <LoadingContext.Provider value={{ loadingStates, setLoadingStatus }}>
      <LoadingSpinner />
      {children}
    </LoadingContext.Provider>
  )
}
