import { User } from "@contextualio/contextual-silo-auth"
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useState } from "react"

import { useUsersService } from "../providers"
import { DeepPartial } from "../types/pages"
import { useLoading } from "./loading"

type UserContext = {
  user?: DeepPartial<User>
  setUser: Dispatch<SetStateAction<DeepPartial<User> | undefined>>
  fetchUser: () => Promise<void>
}

export const UserContext = React.createContext<UserContext | null>(null)
export const useUser = () => useContext(UserContext)!

type Props = PropsWithChildren & {}

export const UserProvider = ({ children }: Props) => {
  const { loadingStates, setLoadingStatus } = useLoading()

  const { getUser } = useUsersService()

  const [user, setUser] = useState<DeepPartial<User>>()
  const fetchUser = useCallback(async () => {
    try {
      const rsp = await getUser()
      setUser(rsp)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingStatus("UserProvider")
    }
  }, [getUser, setLoadingStatus])

  useEffect(() => {
    if (!user) {
      fetchUser()
    }
  }, [fetchUser, user])

  if (loadingStates.UserProvider) {
    return null
  }

  return (
    <UserContext.Provider value={{ user, setUser, fetchUser }}>
      {children}
    </UserContext.Provider>
  )
}
