import Box from "@mui/material/Box"
import Lottie from "lottie-react"
import { useMemo } from "react"

import ContextualAnimationLight from "../../assets/animations/contextual_json_animation_light.json"
import { useSettings } from "../../context/theme"

type Props = {
}

export const Fallback = ({ }: Props) => {
  const { mode } = useSettings()
  const color = useMemo(() => mode === "dark" ? "#141414" : "#f4f5fa", [mode])

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: color,
      }}
    >
      <Box height={500} width={500} display={"flex"} alignContent={"center"}>
        <Lottie animationData={ContextualAnimationLight} />
      </Box>
    </Box>
  )
}
