import { CreateSubscriptionPayload, CreateUserPayload, TenantReservation } from "@contextualio/contextual-silo-auth"
import { useCallback, useEffect, useState } from "react"

import { useAlert } from "../../context/alert"
import { useUser } from "../../context/user"
import { useSingupService, useTenantsService, useUsersService } from "../../providers"
import { CreateTenant } from "../Tenants/create"

type Props = {
}

export const FifthStep = ({ }: Props) => {
  const { createSubscription } = useSingupService()
  const { getTenantReservations } = useTenantsService()
  const { createUser } = useUsersService()
  const { setAlert } = useAlert()
  const { user, setUser } = useUser()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  const [reservations, setReservations] = useState<TenantReservation[]>()

  useEffect(() => {
    const list = async () => {
      setLoading(true)
      try {
        const rsp = await getTenantReservations()
        setReservations(rsp)
      } catch (error) {
        setAlert({
          severity: "error",
        })
      } finally {
        setLoading(false)
      }
    }
    list()
  }, [getTenantReservations, setAlert])

  const handleSubmit = useCallback(async (data: CreateSubscriptionPayload) => {
    try {
      await createSubscription(data)

      const parsed = CreateUserPayload.safeParse(user)
      if (parsed.success) {
        const rsp = await createUser({
          ...parsed.data,
          signupComplete: new Date().toISOString(),
        })
        setUser(rsp)
      }

      setSuccess(true)
    } catch (error) {
      setAlert({
        message: "Error creating tenant",
        severity: "error",
      })
    }
  }, [createSubscription, createUser, setAlert, setUser, user])

  if (loading) {
    return null
  }

  return <CreateTenant onSubmit={handleSubmit} success={success} reservations={reservations} setReservations={setReservations} />
}
