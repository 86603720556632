import env from "@beam-australia/react-env"
import axios, { AxiosInstance } from "axios"
import React, { PropsWithChildren, useContext, useEffect, useMemo } from "react"

import { useAuth0 } from "./auth0"
import { useLoading } from "./loading"

const baseURL = env("API_HOST")

type Context = {
  instance: AxiosInstance
}

export const AxiosContext = React.createContext<Context | null>(null)
export const useAxios = () => useContext(AxiosContext)!.instance

export type AxiosProviderProps = {}

export const AxiosProvider = ({ children }: PropsWithChildren<AxiosProviderProps>) => {
  const { token } = useAuth0()
  const instance = useMemo(() => axios.create({
    baseURL: baseURL + "/api/v1",
    headers: {
      "Content-Type": "application/json",
    },
  }), [])

  const { loadingStates, setLoadingStatus } = useLoading()

  useEffect(() => {
    let i: number | undefined = undefined
    if (token) {
      i = instance.interceptors.request.use(
        config => {
          config.headers = { Authorization: `Bearer ${token}` }
          return config
        },
        error => {
          return Promise.reject(error)
        },
      )
      setLoadingStatus("AxiosProvider")
    }
    return () => {
      if (i !== undefined) {
        instance.interceptors.request.eject(i)
      }
    }
  }, [instance, setLoadingStatus, token])

  if (loadingStates.AxiosProvider) return null

  return (
    <AxiosContext.Provider value={{ instance }}>
      {children}
    </AxiosContext.Provider>
  )
}
