import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button, Stack } from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import { PropsWithChildren, useCallback } from "react"

import { useAuth0 } from "../context/auth0"
import { useSettings } from "../context/theme"
import { useUser } from "../context/user"
import { DropDown } from "./Dropdown"
import { ContextualLogo } from "./Svg"

type Props = PropsWithChildren

const Layout = ({ children }: Props) => {
  const { returnUrl } = useAuth0()
  const { mode } = useSettings()
  const { user } = useUser()

  const handleReturn = useCallback(() => {
    if (!!returnUrl?.includes("?")) {
      window.location.replace(`${returnUrl!}&mode=${mode}`)
    } else {
      window.location.replace(`${returnUrl!}?mode=${mode}`)
    }
  }, [mode, returnUrl])

  const ReturnButton = useCallback(() => {
    if (!returnUrl || !user || !(window.location.pathname === "/new-tenant" || window.location.pathname === "/business-signup")) return null

    const label = window.location.pathname === "/business-signup" ? "Return to Service Console" : "Return to Customer Account"

    return (
      <Button
        component="a"
        variant="text"
        onClick={handleReturn}
        sx={{
          position: "absolute",
          top: "0px",
          left: "10px",
          width: "max-content",
        }}>
        <ArrowBackIcon fontSize="small" sx={{ marginRight: 1.5 }} />
        {label}
      </Button>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReturn, window.location.pathname])

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ReturnButton />
        <DropDown />
        <Stack spacing={2} textAlign={"center"} alignItems={"center"}>
          <ContextualLogo />
          {children}
        </Stack>
      </Box>
    </Container>
  )
}

export default Layout
